import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "form", "input" ]

  connect() {
    this.model        = this.data.get("model")       || "model"
    this.name         = this.data.get("name")        || "name"
    this.input_class  = this.data.get("input-class") || "input"
    this.post_url     = this.data.get("post-url") || window.location.pathname
  }

  toggle() {
    if (!this.data.get("toggled") == 1) {
      this.sourceTarget.innerHTML = this.form()

      this.data.set("toggled", 1)
    }
  }

  close(event) {
    if ((this.element.contains(event.target) === false) &&
      this.data.get("toggled") == 1) {

      this.submit()
    }
  }

  submit() {
    this.formTarget.submit()
  }

  form() {
    return `
      <form class='mb-2 mb-md-5' action="${this.post_url}" accept-charset="UTF-8" data-remote="true" data-target="inline-edit.form" method="post">
        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="_method" value="patch">
        <input type="hidden" name="authenticity_token" value="${this.authenticity_token}">
        <div id='inline-edit-input-wrapper'>
          <input type="text" value="${this.input_value}" name="${this.model}[${this.name}]" class="${this.input_class}" id="${this.model}_${this.name}" data-target="inline-edit.input" data-action="onblur->inline-edit#submit" >
          <button type="submit" class="btn btn-circle btn-sm btn-outline-primary"><i class="fas fa-check"></i></button>
        </div>
      </form>
    `
  }

  get input_value() {
    console.log('this.sourceTarget', this.sourceTarget)
    return $('#editable_value').text();
  }


  // get post_url() {
  //   return this.data.get("post-url") || window.location.pathname
  // }

  get authenticity_token() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }
}
