// MAPS
import 'leaflet/dist/leaflet.css';
require("leaflet");
// import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import * as L from 'leaflet';
// import 'leaflet-defaulticon-compatibility';

// require("leaflet-geosearch");
// import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
require("leaflet.markercluster");
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { GestureHandling } from "leaflet-gesture-handling";


$(document).ready(function() {
  // initMap();
  // initPlaces();
  // initPlaceFormMap();

  // initLocation();
  window.initLocation = function() { initLocation() }
  // initHomeMap();
  // initPopupMap();
  // initGivawayPopupMap();

  $('#mapModal').on('shown.bs.modal', function () {
     // initPopupMap();
     // initGivawayPopupMap();
     $('#myFlipper').flipper('init');
  });
});


function initLocation(ids) {
  if ($('#location_map_wrapper').length) {
    start_loading('#location_map_wrapper');
    let page_id = $('body').data('page_id');
      $.ajax({
        url: "/events/fetch.json",
        type: 'POST',
        data: { ids: ids, page_id: page_id },
        // dataType: 'script',
        format: 'json',
        cache: false,
        success:function (data, status){
          stop_loading('#location_map_wrapper');
          if (data.coordinates === "[]") {
            $('#main-map-wrapper').hide();
          } else {
            initLocationMap(data.current_loc, data.coordinates, 'full');
          }
        }
    });
  }
}


function initLocationMap(current_loc, coordinates, type) {

  if ($('#location_map').length) {
    var container = L.DomUtil.get('location_map');
    if(container != null){
      container._leaflet_id = null;
      $("#location_map").remove();
      $(".map-area").html("<div id='location_map'></div>");
    }

    var current_loc = current_loc;
    var coordinates = JSON.parse(coordinates);

    var map = L.map('location_map', {
      gestureHandling: L.Browser.mobile,
      gestureHandlingOptions: {
        text: {
            touch: I18n.t('js.map.touch'),
            scroll: I18n.t('js.map.scroll'),
            scrollMac: I18n.t('js.map.scrollMac')
        }
      }
    }).setView(current_loc, 13);

    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 2,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    map.on('click', function(ev) {
        console.log('koordinatas', ev.latlng); // ev is an event object (MouseEvent in this case)
    });

     //************************GEO-SEARCH PROVIDER************************
    // var GeoSearchControl = window.GeoSearch.GeoSearchControl;
    // var OpenStreetMapProvider = window.GeoSearch.OpenStreetMapProvider;

    // var provider = new OpenStreetMapProvider();

    var customOptions =
        {
        'maxWidth': '500',
        'minWidth': '200',
        'className' : 'custom'
        }
    var markers = L.markerClusterGroup({showCoverageOnHover: false});

    $.each(coordinates, function( index, value, d ) {
      console.log( 'value', value );
      let icon = L.icon({
        iconUrl: "https://digi.wedding/pub_imgs/digi_pin.png",
        iconSize: [28, 41], // size of the icon
        popupAnchor: [13,-10],
        iconAnchor: [10, 35]
      })

      if (value['img'].length){
        var img = "<img class='w-100' src="+ value['img'] +" />"
      } else {
        var img = ''
      }
      
      let infos = "<div class='pin_popup fs-6 primary-color' id="+value['id']+"><b>"+ value['title'] +"</b><div>"+ value['date'] +"</div>"+ img +"</div>"
      let marker = L.marker([value['x'], value['y']], {icon: icon, bubblingMouseEvents: true, giveaway_id: value['id'], alt: value.title, title: value.title}).bindPopup(infos, customOptions);
            // marker = L.marker([value['x'], value['y']], {icon: icon, bubblingMouseEvents: true, offer_id: value['id']}).bindPopup('', customOptions).setPopupContent(infos);
      // marker.on('click', giveawayMarkerOnClick);

      markers.addLayer(marker);
    });

    map.addLayer(markers);
    map.fitBounds(markers.getBounds(markers), {padding: [50, 50]});

    map.on('popupopen', function(centerMarker) {
      let zoom = map.getZoom();
      let cM = map.project(centerMarker.popup._latlng);
      map.setView(map.unproject(cM), zoom, {animate: true});
    });
    // var addressPoints = [
    //   [-37.8210922667, 175.2209316333, "2"],
    //   [-37.8210819833, 175.2213903167, "3"],
    //   [-37.8210881833, 175.2215004833, "3A"],
    //   [-37.8211946833, 175.2213655333, "1"],
    //   [-37.8194342167, 175.22322975, "9"]
    // ];


    //     for (var i = 0; i < addressPoints.length; i++) {
    //       var a = addressPoints[i];
    //       var title = a[2];
    //       var marker = L.marker(new L.LatLng(a[0], a[1]), { title: title });
    //       marker.bindPopup(title);
    //       markers.addLayer(marker);
    //     }

    //     map.addLayer(markers);

    // L.marker([56.946285, 24.105078], {
    //           draggable: true,
    //           title: 'Hello',
    //           bubblingMouseEvents: true
    //         }).addTo(map).bindPopup("This is the Transamerica Pyramid").openPopup();;

    // marker.bindPopup("This is the Transamerica Pyramid").openPopup();

  }
}





function markerOnClick(e)
{
  // alert("hi. you clicked the marker at " + e.latlng);
  console.log('e', e.target._leaflet_id);
  console.log('offer_id', e.target.options.offer_id);

    var offer_id = e.target.options.offer_id;
    if (offer_id.length) {
    $.ajax({
      url: 'offers/'+ offer_id +'/pin_popup.json',
      url: 'offers/'+ offer_id +'/pin_popup.js',
      type: 'GET',
      // format: 'json',
      format: 'js',
      cache: false,
      success:function (data, status){
        // $('#'+offer_id).html(data.popup_html_data);
      }
    });
  }
}

function giveawayMarkerOnClick(e)
{
  // alert("hi. you clicked the marker at " + e.latlng);
  console.log('e', e.target._leaflet_id);
  console.log('giveaway_id', e.target.options.giveaway_id);

    var giveaway_id = e.target.options.giveaway_id;
    if (giveaway_id.length) {
    $.ajax({
      url: 'giveaways/'+ giveaway_id +'/pin_popup.json',
      url: 'giveaways/'+ giveaway_id +'/pin_popup.js',
      type: 'GET',
      // format: 'json',
      format: 'js',
      cache: false,
      success:function (data, status){
        // $('#'+offer_id).html(data.popup_html_data);
      }
    });
  }
}


function initPlaces(ids) {
  if ($('#places_map_wrapper').length) {
    start_loading('#places_map_wrapper');
      $.ajax({
        url: "/places/fetch.json",
        type: 'POST',
        data: { ids: ids },
        // dataType: 'script',
        format: 'json',
        cache: false,
        success:function (data, status){
          stop_loading('#places_map_wrapper');
          if (data.coordinates === "[]") {
            $('#main-map-wrapper').hide();
          } else {
            initPlacesMap(data.current_loc, data.coordinates, 'full');
          }
        }
    });
  }
}


function updateMarkerData(map, marker, position) {
  marker.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'});
  map.panTo(new L.LatLng(position.lat, position.lng));
  updateLatLangValue(position.lat, position.lng);
}

function updateLatLangValue(lat,lng) {
  if (lat & lng) {
    updateAddressData(lat,lng)
    $('#new-place-longitude').val(lng);
    $('#new-place-latitude').val(lat);
  }
}

function updateAddressData(lat,lng) {
  if (lat & lng) {
    $.ajax({
      url: '/places/update_address_data.json',
      data: { lat: lat, lng: lng },
      success: function(data, status) {
        $('#all_data').html(data.all_data);
        // console.log('country_id', data.country_id);
        $('#place-country').val(data.country_id)
        $('#place-country').trigger('change.select2')
        // $('#place-city').val(data.city).effect("highlight", { color: '#66F792' }, 1000);
        $('#place-address').val(data.full_address);
        $('#place-address-preview').html(data.full_address);
        $('#select2-place-country-container');

        // $item.removeClass('selected-additional-service');
      },
      cache: false
    });
  };
}

function start_loading(element) {
  console.log('element', element);
  $('' + element + '').append("<div class='loader-overlay'><div class='loader'></div></div>");
}

function stop_loading(element) {
  $(element).children(".loader-overlay").fadeOut(800).hide(800);
}


// POPUP map

function initPopupMap() {

  if ($('#popup_map').length) {
    var L = window.L;
    var current_loc = $('#popup_map').data('current_loc');

    // INIT START MAP
    var map = L.map('popup_map', {
      gestureHandling: L.Browser.mobile,
      gestureHandlingOptions: {
        text: {
            touch: I18n.t('js.map.touch'),
            scroll: I18n.t('js.map.scroll'),
            scrollMac: I18n.t('js.map.scrollMac')
        }
      }
    }).setView(current_loc, 6);

    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    var icon = L.icon({
      iconUrl: "https://i.pinimg.com/originals/5e/98/5e/5e985e71d0f954f7c602794318eb323d.png",
      iconSize: [25, 41], // size of the icon
      popupAnchor: [13,-10],
      iconAnchor: [10, 35],
      custom_id: 'MyselectedPin',
      className: 'myPin',
      yolo: 1
    });


    // ADD SERACH CONTROLLER
    var newSearchControl = new L.Control.Search({
      url: 'https://nominatim.openstreetmap.org/search?format=json&q={s}',
      jsonpParam: 'json_callback',
      propertyName: 'display_name',
      propertyLoc: ['lat','lon'],
      container: 'findbox',
      id: 'newSearchControl',
      marker: {
        id: 'searchIco',
        icon: icon,
        draggable: true
      },
      autoCollapse: false,
      collapsed: false,
      autoType: true,
      minLength: 2,
      delayType: 250,
      firstTipSubmit: true,
      textErr: I18n.t('js.search.error'),
      button: false,
      textCancel: I18n.t('js.search.cancel'),
      textPlaceholder: I18n.t('js.search.placeholder')
    })

    map.addControl( newSearchControl );

    // INIT MARKERS CLUSTER


    if ($('#new-offer-latitude').val().length) {
      marker = L.marker(current_loc, {
        icon: icon,
        id: 'yolo',
        bubblingMouseEvents: true,
        draggable: true,
      });

      marker.addTo(map);
      var position = marker.getLatLng();
      updateLatLangValue(position);
    }
    // ========== MAP ON CLICK START ==========
    map.on('click', function(ev) {
      position = ev.latlng

      removeSearchMarker(map);

      if (typeof marker === 'undefined') {
        console.log("tete");
        marker = L.marker(position, {
          icon: icon,
          id: 'yolo',
          bubblingMouseEvents: true,
          draggable: true,
        });

        marker.addTo(map);
        map.setView(position, 18);
        updateLatLangValue(position);

        marker.on('dragend', function(event){
          var marker = event.target;
          var position = marker.getLatLng();
          updateAddressMarkerData(map, marker, position);
        });
      }

      updateAddressMarkerData(map, marker, position);

    });


    // ========== ON SEARCH RESULT ==========
    newSearchControl.on('search:locationfound', function(ev) {
      console.log('ev', ev);
      position = ev.latlng
      map.setView(position, 18);

      removeSearchMarker(map);

      marker = L.marker(position, {
        icon: icon,
        id: 'yolo',
        bubblingMouseEvents: true,
        draggable: true,
      });

      marker.addTo(map);
      updateLatLangValue(position);
      updateAddressMarkerData(map, marker, position);

      marker.on('dragend', function(event){
        var marker = event.target;
        var position = marker.getLatLng();
        updateAddressMarkerData(map, marker, position);
      });
    });

    // ========== ON STANDART DRAG ==========
    if (typeof marker !== 'undefined') {
      marker.on('dragend', function(event){
        var marker = event.target;
        var position = marker.getLatLng();
        updateAddressMarkerData(map, marker, position);
      });
      var position = marker.getLatLng();
      map.setView(position, 18);
    }

  }
}





function updateAddressMarkerData(map, marker, position) {
  marker.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'});
  map.panTo(new L.LatLng(position.lat, position.lng));
  updateLatLangValue(position.lat, position.lng);
}

function initAddresType() {
  $('#user_address_target').select2({
    escapeMarkup: function(markup) {
      return markup;
    },
    templateResult: function (result) {
      return result.text;
    },
    templateSelection: function (selection) {
      return selection.text;
    },
  });
}

function removeSearchMarker(map) {
  map.eachLayer(function (layer) {
    if (layer.options.id === 'searchIco') {
      map.removeLayer(layer);
    }
  });
}
