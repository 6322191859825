import { Controller } from '@hotwired/stimulus'
import { FileInput, Informer, ThumbnailGenerator, Dashboard, ImageEditor } from 'uppy'
import { uppyInstance, uploadedFileData } from '../src/uppy'

export default class extends Controller {
  static targets = [ 'input', 'result', 'preview', 'browsebutton' ]
  static values = { types: Array, server: String }

  connect() {
    console.log('this', this)
    this.inputTarget.classList.add('d-none')

    this.uppy = this.createUppy()

  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
        id: this.inputTarget.id,
        types: this.typesValue,
        server: this.serverValue,
      })
      .use(FileInput, {
        // target: this.inputTarget.parentNode,
        target: this.browsebuttonTarget,
        className: 'ann',
        locale: { strings: { chooseFiles: 'PIEVIENOT ATTĒLU' } },
      })
      .use(Informer, {
        target: this.inputTarget.parentNode,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600,
      })

    uppy.on('upload-success', (file, response) => {
      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      this.resultTarget.value = uploadedFileData(file, response, this.serverValue)
    })

    uppy.on('thumbnail:generated', (file, preview) => {
      this.previewTarget.src = preview
      // var d1 = document.getElementById('image-preview');
      if (!$('.image-remoeve-button').length) {
        let remove_button = '<a class="image-remoeve-button btn btn-rounded btn-outline-secondary m-2 px-3" href="#remove-image"><i class="fa fa-trash d-none d-md-inline m-0"></i><span class="d-md-none d-lg-inline"></span></a>'
        $(remove_button).appendTo( $( ".actions-block" ) );
      }
      $('#placeholder-img').hide();
      $('#image-submit-button').removeClass('d-none');
      // d1.insertAdjacentHTML('afterend', '<a class="btn btn-rounded btn-outline-secondary me-3 px-3" href="#remove-image"><i class="fa fa-trash d-none d-md-inline m-0"></i><span class="d-md-none d-lg-inline"></span></a>');
      console.log('this', this.previewTarget)
    })

    return uppy
  }

  remove() {
    // createUppy()

    // uppy.on('file-removed', (file, reason) => {
    //     removeFileFromUploadingCounterUI(file)

    //     if (reason === 'removed-by-user') {
    //         sendDeleteRequestForFile(file) // You need to implement this yourself
    //     }
    // })
  }
}

