//on document load the lazy way
$(document).on('turbolinks:load', function() {
  if ($("#memory-game-wrapper").length) {
    init();
  }
});


function init() {
  window.click_count = 0;
  window.playGame = false;
  // window.matchingGameDeck = ['card1', 'card1','card2', 'card2','card3', 'card3','card4', 'card4','card5', 'card5','card6', 'card6', 'card7', 'card7','card8', 'card8','card9', 'card9','card10', 'card10'];
  // window.matchingGameDeck = $("#cards").data('cards');
  window.matchingGameDeck = JSON.parse($("#cards").attr('data-cards'));

  window.player = $('input#player').val() || "";
  window.playerContacts = $('input#player_contacts').val() || "";
  let score = 0;
  let cardsmatched;

  // console.log("Ja izmantosiet negodīgus paņēmienus (piemēram, ar javascript konsoles palīdzību) labāka rezultāta iegūšanai, jūsu rezultāts netiks skaitīts!!!")
  $("#gameComplete").hide();
  $("#cards").hide();
  $("#gamePlay").click(function(e) {
    e.preventDefault();
    $("#gameIntro").hide();
    startGame();
    $('html, body').animate({ scrollTop: ($("#game").offset().top - 50) }, 50);
  });

  $(".gameReset").click(function(e) {
    e.preventDefault();
    $("#gameComplete").hide();
    reStartGame();
    playGame = false;
    $("#cards").html("<div class='card'><div class='face front'></div><div class='face back'></div></div>");
    clearTimeout(scoreTimeout);
    startGame();

    click_count = 0;

  });
}

//start game and create cards from deck array
function startGame(){
  set_game_height();
  // $("#game").addClass("solid_bg");
  $('#game').css('background','transparent');
  $('#game').removeClass('box-shadow');
  $("#timer").show();
  $(".gameScore").html("0");
  $("#click_count").html("0");
  $("#gameStats").show();
  $("#cards").show().css("display","block");
  score = 0;
  cardsmatched = 0;
  if (playGame == false) {
      playGame = true;
    let matchingGameDeck = JSON.parse($("#cards").attr('data-cards'));
      // console.log('matchingGameDeck', matchingGameDeck)
    let deck = matchingGameDeck.sort(shuffle);

    for(let i=0;i<19;i++){
        $(".card:first-child").clone().appendTo("#cards");
      }
      // initialize each card's position
      $("#cards").children().each(function(index) {
        let $this = $(this);
        // align the cards to be 3x6 ourselves.
        $this.css({
          "left" : ($this.width()) * (index % 4),
          "top" : ($this.height()) * Math.floor(index / 4)
        });
        // get a pattern from the shuffled deck
        let pattern = deck.pop();
        // visually apply the pattern on the card's back side.
        // $this.find(".back").addClass(pattern);
        $this.find(".back").css('background-image', 'url(' + pattern + ')');
        // embed the pattern data into the DOM element.
        $this.attr("data-pattern",pattern);
        // listen the click event on each card DIV element.
        // $this.click(selectCard(click_count));

        $this.click(function(e) {
          // selectCard(e);

          click_count += 1
          $("#click_count").html(click_count)
          let $this = $(this);
          // we do nothing if there are already two cards flipped.

          if ($(".card-flipped").length > 1) {
            return;
          }
          $(this).addClass("card-flipped");
            // check the pattern of both flipped card 0.7s later.
            if ($(".card-flipped").length == 2) {
            setTimeout(checkPattern,700);
          }


        });


        $this.addClass('front_bg_'+(index + 1));
      });
    timer();
  };
}


//timer for game
function timer() {
        //alert("timer set")
        if (playGame) {
          scoreTimeout = setTimeout(function() {
            score += 10
            // final_score = (score / 1000)
            // console.log("result_time",score)
            // uiScore.html(((++score) / 100));
            // uiScore.html(((++score) / 100));
          // result_time = (score * 10)
          // result_time = score
          // string_time = result_time.toString();
          // miliseconds= string_time.substr(string_time.length -2)
          // seconds=Math.round((result_time/1000)%60)
          // seconds = seconds.toString();
          // if (seconds.toString().length == 1) {
          //  seconds = ('0'+seconds)
          // }
          // minutes=Math.round((result_time/(1000*60))%60)
          // minutes = minutes.toString();
          // if (minutes.toString().length == 1) {
          //  minutes = ('0'+minutes)
          // }
          // hours=Math.round((result_time/(1000*60*60))%24)
          // hours = hours.toString();
          // if (hours.toString().length == 1) {
          //  hours = ('0'+hours)
          // }


          // console.log("result_time",score)
          // console.log("seconds",seconds)
          // console.log("minutes",minutes)
          // console.log("hours",hours)
          $(".gameScore").html(msToTime(score))
            timer();
            // console.log("score",score)
          }, 10);
        };
    };

//shuffle cards
function shuffle() {
  return 0.5 - Math.random();
}

//onclick function add flip class and then check to see if cards are the same
function selectCard(e) {
  click_count += 1
  console.log('e', e)
  e.hide();
  $("#click_count").html(click_count)
  // we do nothing if there are already two cards flipped.
  if ($(".card-flipped").length == 0) {
    return;
  } else {
    console.log('this', $(this))
    $(this).addClass("card-flipped");
    // check the pattern of both flipped card 0.7s later.
    if ($(".card-flipped").size() == 2) {
    setTimeout(checkPattern,700);
    }
  }
}

//if pattern is same remove cards otherwise flip back
function checkPattern() {
  if (isMatchPattern()) {
    $(".card-flipped").removeClass("card-flipped").addClass("card-removed");
      if(document.webkitTransitionEnd){
        $(".card-removed").bind("webkitTransitionEnd",  removeTookCards);
      }else{
        removeTookCards();
      }
    } else {
    $(".card-flipped").removeClass("card-flipped");
  }
}

//put 2 flipped cards in an array then check the image to see if it's the same.
function isMatchPattern() {
  var cards = $(".card-flipped");
  var pattern = $(cards[0]).data("pattern");
  var anotherPattern = $(cards[1]).data("pattern");
  return (pattern == anotherPattern);
}

//check to see if all cardmatched variable is less than 8 if so remove card only otherwise remove card and end game
function removeTookCards() {
  if (cardsmatched < 9){
    cardsmatched++;
    $(".card-removed").remove();
  }else{
    $(".card-removed").remove();
    $("#cards").hide();
    $("#gameComplete").show();
    let id = $("#cards").data('id');
    let test = $("#cards").data('test');
    // ======================= save result ==========================
        $.ajax({
          url: '/games/' + id + '/save_result',
          method: 'POST',
          data: { score: score, pid: true, click_count: click_count, player: player, player_contacts: playerContacts, test: test },
          success:function (data, status) {
            console.log("data", data);
            console.log("status", status);
            
            // window.location.href = "/games/index";
          }
        })
      // ======================= save result ends ==========================
    clearTimeout(scoreTimeout);
  }
}

//recreate the original card , stop the timer and re populate the array with class names
function reStartGame(){
        playGame = false;
        $("#cards").html("<div class='card'><div class='face front'></div><div class='face back'></div></div>");
        clearTimeout(scoreTimeout);
//         matchingGameDeck = ['card1', 'card1','card2', 'card2','card3', 'card3','card4', 'card4','card5', 'card5','card6', 'card6',
// 'card7', 'card7','card8', 'card8','card9', 'card9','card10', 'card10',];
        startGame();
      }





function set_game_height() {
  // $('#game').css('background','transparent');
  // $('#game').removeClass('box-shadow');
  // $('#game').removeClass('solid_bg');

  nav_h = $('header#navigation').height();
  win_h = $(window).height();
  win_w = $(window).width();
  // console.log("win_h",win_h);
  // item_h = ((win_h - nav_h) / 4);
  // console.log("nav_h",nav_h);
  // console.log("win_h",win_h);
  // console.log("item_h",item_h);
  if (win_w < 500 ) {
    item_h = ($('#cards').height() / 5.5);
    $('.card').height(item_h);
    $('.card').width(item_h);

    var uiCards= $("#cards");
      uiCards.children().each(function(index) {
        $(this).css({
          "left" : ($(this).width()) * (index % 5),
          "top" : ($(this).height()) * Math.floor(index / 5)
        });
      });

  }
}





function msToTime(s) {

  function addZ(n) {
    return (n<10? '0':'') + n;
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  ms = ms.toString();

  return addZ(hrs) + ':' + addZ(mins) + ':' + addZ(secs) + ':' + ms.substr(0, 2);
}



