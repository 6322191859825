$(document).on('turbolinks:load', function() {
  removeImage();
  initTableGuestSearch();
  initVendorDealForm();
  // addVendorToFavorites();
})

function removeImage() {

  $(document).on("click", ".image-remoeve-button", function(){
    let url = $(this).attr('remove-url');
    var controller = $(this).attr('controller');
    var att = $(this).attr("attribute");

    // if(controller == 'page') {
    //   let page = {}
    //   page [''+ att + ''] = 'remove';
    //   var data = { image: 'remove', section: { image: '' } }
    // }
    // else {
    //   var data = { image: 'remove', section: { image: '' } }
    // }

    if (url.length) {
      $.ajax({
        url: url,
        type: "PATCH",
        data: { image: 'remove', section: { image: '' } },
        success: function(data, status) {

        }
      });
    }
  });
}

function initTableGuestSearch() {
  $.expr[":"].contains = $.expr.createPseudo(function(arg) {
      return function( elem ) {
          return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
      };
  });

  $('#tableGuestsSearch').on("keyup keydown paste",function() {
    let search_val = $(this).val();
    console.log('search_val', search_val);
    // $( "ul .sidebar_guest-item").css('visibility', 'visible');
    $( ".sidebar_guest-item").addClass('hidden');
    $( "ul .sidebar_guest-item span:contains('" + search_val + "')" ).parent().removeClass('hidden')
    // $span.parent().removeClass('hidden')
  });
}


function initVendorDealForm() {
  $(document).on("change", "#vendor-deal-type", function(){
    let type = $(this).val();
    if(type == 'discount') {
      $('.vendor_deal_discount_percents').show();
      $('#deal-trans-block [id*=vendor_deal_translations_attributes_]').val('')
      $('#vendor-deal-description-wrapper').hide();
    }
    else {
      $('#vendor-deal-description-wrapper').show();
      $('.vendor_deal_discount_percents').hide();
    }
  });

  $(document).on("click", "#vendor_deal_is_permanent", function(){
    toggleVendorExpiresOn();
  })

  $( "#vendor-deal-type" ).trigger( "change" );
  toggleVendorExpiresOn();
}



function toggleVendorExpiresOn() {
  if($('#vendor_deal_is_permanent').is(':checked')) {
    $( ".vendor_deal_expires_on" ).hide();
    $( "#vendor_deal_expires_on" ).val('');
  }
  else {
    $( ".vendor_deal_expires_on" ).show();
  }
}

// function addVendorToFavorites() {
  $(document).on("click", ".addVendorToFavorites", function(){
    let $this = $(this);
    let id = $this.data('vendor_id');
    if ($this.hasClass( "far" )) {
      $this.removeClass("far").addClass("fa");
    } else {
      $this.removeClass("fa").addClass("far");
    }

    if (id) {
      $.ajax({
        url: '/user_vendors/add_to_favorites.js',
        type: "GET",
        data: { vendor_id: id },
        success: function(data, status) {

        }
      });
    }

  });
// }
